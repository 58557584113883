import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

  {
    path: "/pages/starter",
    name: "starter",
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/NerkCo"),
  },
  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintanance", authRequired: true },
    component: () => import("../views/pages/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Comming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  {
    path: "/pages/profile",
    name: "profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("../views/pages/profile/simple"),
  },
  {
    path: "/pages/profile-setting",
    name: "profile-setting",
    meta: { title: "Setting", authRequired: true },
    component: () => import("../views/pages/profile/setting"),
  },
  // {
  //   path: "/pages/ecommerce/productos",
  //   name: "productos",
  //   meta: { title: "Productos", authRequired: true },
  //   component: () => import("../views/pages/ecommerce/productos"),
  // },
  // {
  //   path: "/pages/ecommerce/carrito",
  //   name: "carrito",
  //   meta: { title: "Carrito", authRequired: true },
  //   component: () => import("../views/pages/ecommerce/carrito"),
  // },
  {
    path: "/catalogos/usuarios",
    name: "cat-usuarios",
    meta: { title: "Usuarios", authRequired: true },
    component: () => import("../views/catalogos/usuarios"),
  },
  {
    path: "/catalogos/clientes",
    name: "cat-clientes",
    meta: { title: "Clientes", authRequired: true },
    component: () => import("../views/catalogos/clientes"),
  },
  {
    path: "/catalogos/vendedores",
    name: "cat-vendedores",
    meta: { title: "Vendedores", authRequired: true },
    component: () => import("../views/catalogos/vendedores"),
  },    
  {
    path: "/catalogos/tipohabitacion",
    name: "cat-tipohabitacion",
    meta: { title: "Tipos Habitación", authRequired: true },
    component: () => import("../views/catalogos/tipohabitacion"),
  },  
  {
    path: "/catalogos/operadora",
    name: "cat-operadora",
    meta: { title: "Operadoras", authRequired: true },
    component: () => import("../views/catalogos/operadora"),
  },  
  {
    path: "/catalogos/agente",
    name: "cat-agente",
    meta: { title: "Agentes", authRequired: true },
    component: () => import("../views/catalogos/agente"),
  },  
  {
    path: "/viajes/productos",
    name: "viajes-productos",
    meta: { title: "Crear Viaje", authRequired: true },
    component: () => import("../views/viajes/productos"),
  },
  {
    path: "/viajes/viajes",
    name: "viajes-viajes",
    meta: { title: "Listado de Viajes", authRequired: true },
    component: () => import("../views/viajes/viajes"),
  },
  {
    path: "/viajes/viaje",
    name: "viajes-viaje",
    meta: { title: "Crear Viajes", authRequired: true },
    component: () => import("../views/viajes/viaje"),
  },
  {
    path: "/viajes/viajeseguimiento",
    name: "viajes-viajeseguimiento",
    meta: { title: "Seguimiento de Viaje", authRequired: true },
    component: () => import("../views/viajes/viajeseguimiento"),
  },  
  {
    path: "/viajes/viajeexpediente/:viajeId",
    name: "viajes-viajeexpediente",
    meta: { title: "Expediente Viaje", authRequired: true },
    component: () => import("../views/viajes/viajeexpediente"),
  },
  {
    path: "/viajes/detalle/:viajeId",
    name: "viajes-detalle",
    meta: { title: "Detalle", authRequired: true },
    component: () => import("../views/viajes/detalle"),
  },
  {
    path: "/viajes/booking/:viajeClienteId",
    name: "viajes-booking",
    meta: { title: "Booking", authRequired: true },
    component: () => import("../views/viajes/booking"),
  },
  {
    path: "/viajes/cxc",
    name: "viajes-cxc",
    meta: { title: "CxC", authRequired: true },
    component: () => import("../views/viajes/cxc"),
  },
  {
    path: "/viajes/cxccliente/:clienteId?",
    name: "viajes-cxccliente",
    meta: { title: "CxC Cliente", authRequired: true },
    component: () => import("../views/viajes/cxccliente"),
  },
  {
    path: "/viajes/reciboviaje",
    name: "viajes-recibo",
    meta: { title: "ReciboTest", authRequired: true },
    component: () => import("../views/viajes/reciboviaje"),
  },
];